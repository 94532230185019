import seedrandom from "seedrandom";
import { differenceInCalendarDays, addDays, parse } from "date-fns";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import ClipboardJS from "clipboard";
import store from "store2";
import { createPopper } from "@popperjs/core";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const fpPromise = FingerprintJS.load();
const firstJimdle = new Date("2022-04-26T12:00:00");
const today = new Date();
const ls = store.namespace("jimdle");
const statsKey = "stats";
const historyKey = "history";
const squares = ["🟥", "🟧", "🟨", "🟩", "🟦", "🟪", "🟫", "⬛", "⬜"];
const rare = ["✅", "❇️"];
let statsInitialized = false;

// Update Stats
const stats = ls.get(statsKey) || [];
stats.push(today.toDateString());
ls.set(statsKey, [...new Set(stats)]);

(async () => {
  const statsButton = document.getElementById("stats-button");
  statsButton.onclick = function () {
    showStats();
  };

  document.getElementById("stats-close").onclick = function () {
    hideStats();
  };

  const jimdleNumber = differenceInCalendarDays(today, firstJimdle) + 1;
  document.getElementById("jimdleNumber").innerHTML = jimdleNumber;

  let squareString = await getJimdle(jimdleNumber);

  document.getElementById("squares").innerHTML = squareString;

  const popcorn = document.querySelector("#share");
  const tooltip = document.querySelector("#tooltip");

  const clipboard = new ClipboardJS("#share", {
    text: () => {
      return `${squareString}\n #jimdle ${jimdleNumber}\n https://jimdle.app`;
    },
  });

  const popperInstance = createPopper(popcorn, tooltip, {
    placement: "top",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  clipboard.on("success", () => {
    show(tooltip, popperInstance);

    setTimeout(() => {
      hide(tooltip);
    }, 5000);
  });

  // Update History
  const history = ls.get(historyKey) || [];
  if (!history.find((el) => el.date === today.toDateString())) {
    history.push({
      date: today.toDateString(),
      squares: squareString,
    });
  }

  ls.set(historyKey, history);
})();

async function getJimdle(jimdleNumber) {
  // Get the visitor identifier when you need it.
  const fp = await fpPromise;
  const result = await fp.get();

  var rng = seedrandom(`${result.visitorId}${today.toDateString()}`);

  const indices = `${Math.abs(rng.int32())}`.split("");

  const specialJimdle = specialNumbers(jimdleNumber, indices);

  if (specialJimdle) {
    return specialJimdle;
  }

  const sum = indices.reduce(
    (partialSum, a) => partialSum + parseInt(a, 10),
    0
  );
  let jimdle = "";

  for (let i = 0; i < indices.length; i++) {
    const index = parseInt(indices[i], 10);

    if (index >= squares.length) {
      jimdle += rare[(sum + i) % 2];
    } else {
      jimdle += squares[index];
    }
  }

  return jimdle;
}

function specialNumbers(jimdleNumber, indices) {
  switch (jimdleNumber) {
    case 69:
      const evenOdd =
        indices.reduce((partialSum, a) => partialSum + parseInt(a, 10), 0) % 2;

      let jimdleString = "";
      for (let i = 0; i < indices.length; i++) {
        const index = parseInt(indices[i], 10);
        if (index >= squares.length || index % 2 === evenOdd) {
          jimdleString += "🍆";
        } else {
          jimdleString += squares[index];
        }
      }

      return jimdleString;
    default:
      break;
  }

  return false;
}

function show(tooltip, popperInstance) {
  tooltip.setAttribute("data-show", "");
  popperInstance.update();
}

function hide(tooltip) {
  tooltip.removeAttribute("data-show");
}

function showStats() {
  const statsModal = document.getElementById("stats");
  statsModal.classList.remove("hidden");
  statsModal.classList.add("opacity-100");
  statsModal.classList.remove("opacity-0");

  initStats();
}

function hideStats() {
  const statsModal = document.getElementById("stats");
  statsModal.classList.remove("opacity-100");
  statsModal.classList.add("opacity-0");
  statsModal.classList.add("hidden");
}

function initStats() {
  if (statsInitialized) {
    return;
  }

  statsInitialized = true;

  _getStreaks();
  _getSpreadChart();
}

function _getStreaks() {
  const dates = ls.get(statsKey) || [];
  const sorted = dates
    .map((d) => parse(d, "EEE MMM dd yyyy", today))
    .sort((a, b) => a - b);

  const streaks = sorted.reduce(
    (output, start, i, dates) => {
      const next = dates[i + 1];
      if (addDays(start, 1) - next === 0) {
        output.current += 1;
      } else {
        // Save new max
        if (output.current > output.max) {
          output.max = output.current;
        }

        // If we arent at the end of the array, reset count.
        if (i < dates.length - 1) {
          output.current = 1;
        }
      }

      return output;
    },
    { current: 1, max: 0 }
  );

  document.getElementById("current-streak").innerHTML = streaks.current;
  document.getElementById("max-streak").innerHTML = streaks.max;
}

function _getSpreadChart() {
  const ctx = document.getElementById("spread-chart");

  const history = ls.get(historyKey) || [];
  const possibilities = squares.concat(rare);
  const counts = new Array(possibilities.length).fill(0);

  history.forEach((h) => {
    for (let i = 0; i < possibilities.length; i++) {
      const element = possibilities[i];
      const matches = h.squares.match(new RegExp(element, "g"));
      if (matches) {
        counts[i] += matches.length;
      }
    }
  });

  const spreadChart = new Chart(ctx, {
    type: "bar",
    data: {
      labels: possibilities,
      datasets: [
        {
          label: "Occurrences",
          data: counts,
          borderWidth: 2,
          backgroundColor: [
            "red",
            "orange",
            "yellow",
            "green",
            "blue",
            "purple",
            "brown",
            "black",
            "white",
            "green",
            "green",
          ],
        },
      ],
    },
    options: {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    },
  });
}
